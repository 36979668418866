
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import membersApi from "@/core/services/MembersApi";
import institutionApi from "@/core/services/InstitutionApi";
import chapitresApi from "@/core/services/ChapitresApi";
import promotionsApi from "@/core/services/PromotionsApi";
import { Form, Field } from "vee-validate";
import { object, string, array } from "yup";
import RepositoriesApi from "@/core/services/RepositoriesApi";
import { ElMessage } from "element-plus";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
    name: "RepoDetails",
    components: { Field, Form },
    data() {
        return {
            loading: true,
            editing: false,
            repoSearchQuery: "",
            selectedElements: [] as any,
            elements: [] as any,
            filters: {
                sex: "",
            },
            elementSearch: "",
            periodFrom: "",
            periodTo: "",
            selectedValue: "",
            filterGradeSearch: "",
            repoType: "",
            repoName: "" as any,
            formattedRepoName: "",
            repoTh: [{ label: "Name", class: "min-w-60px", colName: "name" }] as any,
            currentPage: 1,
            totalPages: "" as any,
            elements_count: "",
            actualFilter: "",
            actualCol: "",
            newEntryLabel: "",
            newEntryLabelFr: "",
            newEntryArticle: "",
            newEntryArticleFr: "",
            newEntryParent: "",
            newEntrySeniority: "",
            newEntryAmount: "",
            newEntryOrder: "",
            newEntryDueType: "",
            newEntryPerimeter: [],
            bailliagesOptions: [] as any,
            replacedRepoOptions: [] as any,
            apiURLRepoSlug: "",
            entry: {} as any,
            patchPayload: {
                label: "",
            } as any,
            patchEntryId: "",
            repoEntry: {
                label: "",
                id: "",
            },
            dueTypes: [],
            replacedRepo: "",
            affectedElementsNb: "",
            perm_pos: "",
        };
    },

    setup() {
        const route = useRoute();
        const router = useRouter();

        const addRepoEntryRef = ref<null | HTMLElement>(null) as any;
        const deleteRepoRef = ref<null | HTMLElement>(null) as any;

        const repoBailliage = object().shape({
            entryLabel: string().required().label("English label").nullable(),
            entryLabelFr: string().required().label("French label").nullable(),
            entryArticle: string().required().label("English article").nullable(),
            entryArticleFr: string().required().label("French article").nullable(),
            entryParent: string().required().label("Parent").nullable(),
        });
        const repoCommander = object().shape({
            entryLabel: string().required().label("Label").nullable(),
            entrySeniority: string().required().label("Seniority").nullable(),
        });
        const repoPriceCategory = object().shape({
            entryDueType: string().required().label("Due type").nullable(),
            entryLabel: string().required().label("Label").nullable(),
            entryAmount: string().required().label("Amount").nullable(),
            entryPerimeter: array().min(1, "At least one perimeter is needed").label("Perimeter").nullable(),
        });
        const repoClassic = object().shape({
            entryLabel: string().required().label("Label").nullable(),
        });

        const replaceShema = object().shape({
            replacedEntry: string().required().label("Replaced with").nullable(),
        });

        const closeModal = () => {
            hideModal(addRepoEntryRef.value);
        };

        const closeRepoModal = () => {
            hideModal(deleteRepoRef.value);
        };

        function createDebounce() {
            let timeout: any;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }

        const resultsPerPage = 10;

        enum IS_BAILLIAGE {
            "national-bailliages",
            "provincial-bailliages",
            "regional-bailliages",
        }

        enum IS_LABEL_ONLY {
            "chapitre-types",
            "cotisation-types",
            "activity-sectors",
            "credit-cards",
            "food-styles",
            "functions",
            "establishment-types",
            "marital-statuses",
            "medals",
            "nationalities",
            "titles",
            "promotion-types",
        }

        enum IS_PRICE_CATEGORY {
            "cotisation-price-categories",
            "promotion-price-categories",
        }

        enum IS_COMMANDER {
            "commanders",
        }

        enum IS_GRADE {
            "grades",
        }

        return {
            route,
            router,
            IS_BAILLIAGE,
            IS_LABEL_ONLY,
            IS_PRICE_CATEGORY,
            IS_COMMANDER,
            IS_GRADE,
            resultsPerPage,
            repoBailliage,
            repoClassic,
            repoCommander,
            replaceShema,
            repoPriceCategory,
            addRepoEntryRef,
            deleteRepoRef,
            closeModal,
            closeRepoModal,
            debounce: createDebounce(),
        };
    },
    mounted() {
        this.repoName = this.route.params.repo_path;
        this.getRepoType(this.route.params.repo_path);
        this.fetchData();
        const re = /-/gi;
        this.formattedRepoName = this.route.params.repo_name as string;
        this.editing = false;
        this.perm_pos = localStorage.getItem("perm_pos") as any;

        if (this.formattedRepoName === "" || this.formattedRepoName === undefined) {
            this.router.push({ name: "repositories" });
        }

        setCurrentPageBreadcrumbs(this.formattedRepoName, [
            {
                link: "/repositories",
                label: "Repositories",
            },
        ]);
        this.loading = false;
    },
    methods: {
        handleSizeChange() {
            this.fetchData();
        },
        resetForm() {
            this.editing = false;
            this.newEntryPerimeter = [];
            this.newEntryOrder =
                this.newEntryLabel =
                this.newEntryLabelFr =
                this.newEntryArticle =
                this.newEntryArticleFr =
                this.newEntryParent =
                this.newEntrySeniority =
                this.newEntryAmount =
                this.newEntryDueType =
                    "";
            setTimeout(() => {
                (document.querySelector("#resetForm") as any).click();
            }, 50);
        },
        prepareRepoDeletion(entry: any) {
            this.repoEntry.label = entry.label;
            this.repoEntry.id = entry.id;
            this.fetchData(true);

            const payload = {
                repo_type: this.repoName,
                object_id: entry.id,
            };
            this.loading = true;
            RepositoriesApi.getAffectedElements(payload).then((res: any) => {
                this.affectedElementsNb = res.data.affected_objects;
                this.loading = false;
            });
        },
        deleteRepo() {
            this.loading = true;
            const payload = {
                delete: this.repoEntry.id,
                replace: this.replacedRepo,
            };

            RepositoriesApi.replaceEntry(payload, this.apiURLRepoSlug).then((response) => {
                if (response.success) {
                    ElMessage({
                        type: "success",
                        message: "Entry deleted and replaced",
                    });
                    this.fetchData();
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred",
                    });
                }
                this.closeRepoModal();
                this.loading = false;
            });
        },
        getRepoType(repo: any) {
            for (let item in this.IS_BAILLIAGE) {
                if (repo === item) {
                    this.repoType = "IS_BAILLIAGE";
                    this.fetchAllBailliages();
                    return;
                }
            }
            for (let item in this.IS_LABEL_ONLY) {
                if (repo === item) {
                    this.repoType = "IS LABEL ONLY";
                    return;
                }
            }
            for (let item in this.IS_PRICE_CATEGORY) {
                if (repo === item) {
                    this.repoType = "IS PRICE CAT";
                    return;
                }
            }
            for (let item in this.IS_COMMANDER) {
                if (repo === item) {
                    this.repoType = "IS COMMANDER";
                    return;
                }
            }
            for (let item in this.IS_GRADE) {
                if (repo === item) {
                    this.repoType = "IS GRADE";
                    return;
                }
            }
        },
        // I'm sorry for these functions, I know they're ugly lol
        fetchData(full?: boolean) {
            full ? "" : (this.loading = true);
            switch (this.repoName) {
                case "national-bailliages":
                    this.fetchNationalBailliages(full);
                    this.apiURLRepoSlug = "bailliage";
                    break;
                case "provincial-bailliages":
                    this.fetchProvincialBailliages(full);
                    this.apiURLRepoSlug = "bailliage";
                    break;
                case "regional-bailliages":
                    this.fetchRegionalBailliages(full);
                    this.apiURLRepoSlug = "bailliage";
                    break;
                case "chapitre-types":
                    this.fetchChapitreTypes(full);
                    this.apiURLRepoSlug = "chapitre-type";
                    break;
                case "cotisation-types":
                    this.fetchCotisationTypes(full);
                    this.apiURLRepoSlug = "cotisation-type";
                    break;
                case "activity-sectors":
                    this.fetchActivitySectors(full);
                    this.apiURLRepoSlug = "activity-sector";
                    break;
                case "credit-cards":
                    this.fetchCreditCards(full);
                    this.apiURLRepoSlug = "credit-card";
                    break;
                case "food-styles":
                    this.fetchFoodStyles(full);
                    this.apiURLRepoSlug = "food-style";
                    break;
                case "functions":
                    this.fetchFunctions(full);
                    this.apiURLRepoSlug = "function";
                    break;
                case "establishment-types":
                    this.fetchEstablishmentTypes(full);
                    this.apiURLRepoSlug = "institution-type";
                    break;
                case "marital-statuses":
                    this.fetchMaritalStatuses(full);
                    this.apiURLRepoSlug = "marital-status";
                    break;
                case "medals":
                    this.fetchMedals(full);
                    this.apiURLRepoSlug = "medal";
                    break;
                case "nationalities":
                    this.fetchNationalities(full);
                    this.apiURLRepoSlug = "nationality";
                    break;
                case "titles":
                    this.fetchTitles(full);
                    this.apiURLRepoSlug = "title";
                    break;
                case "promotion-types":
                    this.fetchPromotionTypes(full);
                    this.apiURLRepoSlug = "promotion-type";
                    break;
                case "cotisation-price-categories":
                    this.fetchCotisationPriceCategories(full);
                    this.apiURLRepoSlug = "cotisation-price-category";
                    break;
                case "promotion-price-categories":
                    this.fetchPromotionPriceCategories(full);
                    this.apiURLRepoSlug = "promotion-price-category";
                    break;
                case "commanders":
                    this.fetchCommanders(full);
                    this.apiURLRepoSlug = "commander";
                    break;
                case "grades":
                    this.fetchGrades(full);
                    this.apiURLRepoSlug = "grade";
                    break;
                default:
                    this.router.push({ name: "404" });
                    break;
            }
        },
        fetchAllBailliages() {
            membersApi.listBailliages({ p: 1, presult: 9999 }).then((response) => {
                this.bailliagesOptions = response.data.bailliages_list;
                this.loading = false;
            });
        },
        fetchNationalBailliages(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.listBailliages({ p, presult, type: "National", query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.bailliages_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.bailliages_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.bailliages_count;
                this.loading = false;
                this.repoTh = [
                    { label: "EN Label", class: "min-w-60px", colName: "en_label" },
                    { label: "FR Label", class: "min-w-60px", colName: "fr_label" },
                    { label: "EN Article", class: "min-w-60px", colName: "en_article" },
                    { label: "FR Article", class: "min-w-60px", colName: "fr_article" },
                ];
            });
        },
        fetchProvincialBailliages(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.listBailliages({ p, presult, type: "Provincial", query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.bailliages_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.bailliages_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.bailliages_count;
                this.loading = false;
                this.repoTh = [
                    { label: "EN Label", class: "min-w-60px", colName: "en_label" },
                    { label: "National Bailliage", class: "min-w-60px", colName: "nBailliage" },
                    { label: "FR Label", class: "min-w-60px", colName: "fr_label" },
                    { label: "EN Article", class: "min-w-60px", colName: "en_article" },
                    { label: "FR Article", class: "min-w-60px", colName: "fr_article" },
                ];
            });
        },
        fetchRegionalBailliages(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.listBailliages({ p, presult, type: "Regional", query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.bailliages_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.bailliages_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.bailliages_count;
                this.loading = false;
                this.repoTh = [
                    { label: "EN Label", class: "min-w-60px", colName: "en_label" },
                    { label: "Associated with", class: "min-w-60px", colName: "parent" },
                    { label: "FR Label", class: "min-w-60px", colName: "fr_label" },
                    { label: "EN Article", class: "min-w-60px", colName: "en_article" },
                    { label: "FR Article", class: "min-w-60px", colName: "fr_article" },
                ];
            });
        },
        fetchActivitySectors(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getActivitySectors({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchChapitreTypes(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            chapitresApi.getChapitreTypes({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchCotisationTypes(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getDuesTypes({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchCreditCards(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            institutionApi.getCreditCards({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchFoodStyles(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            institutionApi.getFoodStyles({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchFunctions(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getFunctions({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchEstablishmentTypes(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getInstitutionTypes({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchMaritalStatuses(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getMaritalStatuses({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchMedals(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getMedals({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchCommanders(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getCommanders({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
            this.repoTh = [
                { label: "Name", class: "min-w-60px", colName: "name" },
                { label: "Seniority", class: "min-w-60px", colName: "seniority" },
            ];
        },
        fetchNationalities(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getNationalities({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchTitles(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getTitles({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchPromotionTypes(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            promotionsApi.getPromotionsTypes({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
        },
        fetchCotisationPriceCategories(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getPriceCategories({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
            this.repoTh = [
                { label: "Name", class: "min-w-60px", colName: "name" },
                { label: "Amount", class: "min-w-60px", colName: "amount" },
                { label: "Perimeter", class: "min-w-60px", colName: "perimeter" },
            ];
            membersApi.getDuesTypes({ p: 1, presult: 9999 }).then((response) => {
                this.dueTypes = response.data.object_list;
            });
        },
        fetchPromotionPriceCategories(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            promotionsApi.getPromotionsPriceCategories({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
            this.repoTh = [
                { label: "Name", class: "min-w-60px", colName: "name" },
                { label: "Amount", class: "min-w-60px", colName: "amount" },
            ];
        },
        fetchGrades(full?: boolean) {
            const p = full ? 1 : this.currentPage;
            const presult = full ? 9999 : this.resultsPerPage;
            const query = this.repoSearchQuery;
            membersApi.getGrades({ p, presult, query }).then((response) => {
                if (full) {
                    this.replacedRepoOptions = response.data.object_list.sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
                } else {
                    this.elements = response.data.object_list;
                    this.totalPages = response.data.page_count;
                }
                this.elements_count = response.data.object_count;
                this.loading = false;
            });
            this.repoTh = [
                { label: "Name", class: "min-w-60px", colName: "name" },
                { label: "Order", class: "min-w-60px", colName: "order" },
            ];
        },
        handlePagination(pageNumber: any) {
            this.selectedElements = [];
            var topCheckbox = document.getElementById("topCheckbox") as any;
            topCheckbox.checked = false;

            this.currentPage = pageNumber;
            this.fetchData();
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(document.getElementsByClassName("chevrons-container") as any).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.fetchData();
        },
        addEntry(values: any) {
            this.resetForm();
            var payload = {
                label: values.entryLabel,
                type:
                    this.repoName === "national-bailliages"
                        ? "National"
                        : this.repoName === "regional-bailliages"
                        ? "Regional"
                        : this.repoName === "provincial-bailliages"
                        ? "Provincial"
                        : null,
            } as any;
            if (this.apiURLRepoSlug === "commander") {
                payload = {
                    ...payload,
                    membership_time_condition: values.entrySeniority,
                };
            }
            if (this.apiURLRepoSlug === "promotion-price-category" || this.apiURLRepoSlug === "cotisation-price-category") {
                var isCdr, isOmgd;
                isCdr = isOmgd = false;

                values.entryPerimeter.map((perimeter: any) => {
                    if (perimeter === "cdr") isCdr = true;
                    if (perimeter === "omgd") isOmgd = true;
                });

                payload = {
                    ...payload,
                    amount: values.entryAmount,
                    is_cdr: isCdr,
                    is_omgd: isOmgd,
                };
            }
            RepositoriesApi.addEntry(this.apiURLRepoSlug, payload).then((response: any) => {
                if (response.success) {
                    this.fetchData();
                    this.closeModal();
                    ElMessage({
                        type: "success",
                        message: "Entry added!",
                    });
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while adding the entry.",
                    });
                }
            });
        },
        // Triggered before the patch fct
        editEntry(entry: any) {
            this.editing = true;
            this.patchEntryId = entry.id;
            this.newEntryLabel = entry.label;
            this.newEntryLabelFr = entry.label_fr ? entry.label_fr : "-";
            this.newEntryArticle = entry.particle ? entry.particle : "-";
            this.newEntryArticleFr = entry.particle_fr ? entry.particle_fr : "-";
            this.newEntryParent = entry.parent ? entry.parent.id : null;
            this.newEntrySeniority = entry.membership_time_condition ? entry.membership_time_condition : null;
            this.newEntryAmount = entry.amount ? entry.amount : null;
            this.newEntryOrder = entry.order;
        },
        patchEntry(values: any) {
            this.patchPayload = {
                label: values.entryLabel,
                type:
                    this.repoName === "national-bailliages"
                        ? "National"
                        : this.repoName === "regional-bailliages"
                        ? "Regional"
                        : this.repoName === "provincial-bailliages"
                        ? "Provincial"
                        : null,
            };
            if (this.apiURLRepoSlug === "bailliage" && values.entryParent) {
                console.log(values);

                this.newEntryParent = values.entryParent ? values.entryParent : null;
                this.patchPayload = {
                    ...this.patchPayload,
                    parent: values.entryParent,
                    label_fr: values.entryLabelFr,
                    particle: values.entryArticle,
                    particle_fr: values.entryArticleFr,
                };
            } else if (this.repoName === "national-bailliages") {
                this.patchPayload = {
                    ...this.patchPayload,
                    label_fr: values.entryLabelFr,
                    particle: values.entryArticle,
                    particle_fr: values.entryArticleFr,
                };
            } else if (this.apiURLRepoSlug === "promotion-price-category" || this.apiURLRepoSlug === "cotisation-price-category") {
                this.patchPayload = {
                    ...this.patchPayload,
                    amount: values.entryAmount,
                };
            } else if (this.apiURLRepoSlug === "commander") {
                this.patchPayload = {
                    ...this.patchPayload,
                    membership_time_condition: values.entrySeniority,
                };
            } else if (this.apiURLRepoSlug === "grade") {
                this.patchPayload = {
                    ...this.patchPayload,
                    order: values.entryOrder,
                };
            }
            RepositoriesApi.patchEntry(this.patchEntryId, this.apiURLRepoSlug, this.patchPayload).then((res: any) => {
                if (res.success) {
                    this.fetchData();
                    this.closeModal();
                    ElMessage({
                        type: "success",
                        message: "Entry updated!",
                    });
                } else {
                    ElMessage({
                        type: "error",
                        message: "An error occurred while updating the entry.",
                    });
                }
            });
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up") chevrons = Array.from(document.getElementsByClassName("fa-chevron-up") as any);
            else if (direction === "down") chevrons = Array.from(document.getElementsByClassName("fa-chevron-down") as any);

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(document.getElementsByClassName("custom-checkbox-repo") as any);

            this.selectedElements = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });

                this.elements.map((repo: any) => {
                    this.selectedElements.push(repo.id);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedElements = [];
            }
        },
        handleActionSelect(repo: any) {
            var focusedCheckbox = document.getElementById("checkbox" + repo.id) as any;

            if (focusedCheckbox?.checked) {
                this.selectedElements.push(repo.id);
            } else {
                this.selectedElements.map((repo: any) => {
                    if (repo.id === repo.id) {
                        this.selectedElements.splice(this.selectedElements.indexOf(repo), 1);
                    }
                });
            }
        },
    },
});
